import './web-component.js';

// NB: Path to this file is : elements/{tag-name}/v1.0/web-component.shopify.js, always use common files from rating tag path
import(
  // @ts-expect-error network import
  // eslint-disable-next-line import/no-unresolved, import/extensions
  '../../amazon-product-rating/v1.0/default.variant.selector.shopify.js'
).catch((err) => {
  console.error('Failed to load Prime variant selector', err);
});
