import { getTargetId } from '../getTargetId.js';

// NB: Path to this file is : elements/{tag-name}/v1.0/web-component.shopify.js, always use common files from rating tag path
const elementsJsImport = `../../amazon-product-rating/v1.0/elements.js?targetId=${getTargetId()}`;
import(
  /* webpackIgnore: true */
  // eslint-disable-next-line import/no-unresolved, import/extensions
  elementsJsImport
).catch((err) => {
  console.error('Failed to load elements.js', err);
});
